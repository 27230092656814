import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuRouterService {

  constructor() { }
  mRouter = '/overviewtj/home';
}
