export class InstrumentCompany {
  constructor(id?: string, kind?: string,
    inputEnergy?: number, communicationInterface?: number,
    traceabilityType?: string,
    installDate?: Date,
    calibrationDate?: Date,
    effectiveDate?: Date
  ) {
    this.id = id;
    this.instrumentLedgerType = null;
    this.companyId = null;
    this.name = null;
    if (kind) this.kind = kind;
    if (inputEnergy) this.inputEnergy = inputEnergy;
    this.specification = null;
    this.accuracyLevel = null;
    this.measureRange = null;
    this.manufacturer = null;
    this.serialNumber = null;
    this.energyElementName = null;
    this.energyManagementNumber = null;
    this.location = null;
    if (installDate) this.installDate = new Date(installDate);
    this.status = null;
    if (communicationInterface) this.communicationInterface = communicationInterface;
    this.installFactory = null;
    this.accessSystem = null;
    if (traceabilityType) this.traceabilityType = traceabilityType;
    this.traceabilityCompany = null;
    this.traceabilityCycle = null;
    if (calibrationDate) this.calibrationDate = new Date(calibrationDate);
    if (effectiveDate) this.effectiveDate = new Date(effectiveDate);
    this.certificateNumber = null;
    this.verificationConclusion = null;
    this.remark = null;
    this.companyName = null;
    this.kindName = null;
    this.traceabilityTypeName = null;
    this.ledgerStatus = null;
    this.inputEnergyName = null; //输入/输出用能名称
    this.communicationInterfaceName = null; //有无通信接口名称
  }
  id: string;
  instrumentLedgerType: string;
  companyId: string;
  name: string;
  kind: string;
  inputEnergy: number;
  specification: string;
  accuracyLevel: string;
  measureRange: string;
  manufacturer: string;
  serialNumber: string;
  energyElementName: String;
  energyManagementNumber: string;
  location: string;
  installDate: Date;
  status: string;
  communicationInterface: number;
  installFactory: string;
  accessSystem: string;
  traceabilityType: string;
  traceabilityCompany: string;
  traceabilityCycle: string;
  calibrationDate: Date;
  effectiveDate: Date;
  certificateNumber: string;
  verificationConclusion: string;
  remark: string;
  companyName: string;
  kindName: string;
  traceabilityTypeName: string;
  ledgerStatus: string;
  inputEnergyName: string; //输入/输出用能名称
  communicationInterfaceName: string; //有无通信接口名称
}
