import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class EnergyPostFileService {

  constructor(private http: HttpClient) { }

  getCompany(url:string):Promise<any>{
    return this.http.get(url).toPromise().then(result=>{
      return result;
    });
  }

  getCompanyEnergyPosition(url:string):Promise<any>{
    return this.http.get(url).toPromise().then(result=>{
      return result;
    });
  }

  getEnergyPositionById(url:string):Promise<any>{
    return this.http.get(url).toPromise().then(result=>{
      return result;
    });
  }

  delEnergyPositionById(url:string):Promise<any>{
    return this.http.delete(url).toPromise().then(result=>{
      return result;
    });
  }

  saveEnergyPosition(url:string,energyPosition:any):Promise<any>{
    return this.http.post(url,energyPosition).toPromise().then(result=>{
      return result;
    });
  }

  updateEnergyPosition(url:string,energyPosition:any):Promise<any>{
    return this.http.put(url,energyPosition).toPromise().then(result=>{
      return result;
    });
  }

  getRegional(url:string):Promise<any>{
    return this.http.get(url).toPromise().then(result=>{
      return result;
    });
  }

  getEnergyPositionByRegional(url:string):Promise<any>{
    return this.http.get(url).toPromise().then(result=>{
      return result;
    });
  }

  submitApplication(data:any):Promise<any>{
    const url = data.url;
    // return this.http.post<boolean>(url, data);
    const energyPosition = data.energyPosition;
    return this.http.put(url,energyPosition).toPromise().then(result=>{
      return result;
    });
  }


}
